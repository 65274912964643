import { MenuItemsType, TrophyIcon, TrophyFillIcon, DropdownMenuItems, MedalGoldIcon } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Lottery'),
        href: '/lottery',
        icon: TrophyIcon,
        fillIcon: TrophyIcon,
        supportChainIds: SUPPORT_ONLY_BSC,
      },
      {
        label: t('Predictions'),
        href: '/prediction',
        icon: MedalGoldIcon,
        supportChainIds: SUPPORT_ONLY_BSC,
      }
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
