import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Hashfair Web3',
  defaultTitle: 'Hashfair Games',
  description: 'Play Decentralized Web3 Games On Hashfair',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@HashfairGames',
    site: '@HashfairGames',
  },
  openGraph: {
    title: 'HASHFAIR GAMES',
    description: 'PLAY DECENTRALIZED WEB3 GAMES ON HASHFAIR',
    images: [{ url: 'https://hashfair.io/favicon.ico' }],
  },
}
